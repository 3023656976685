import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(private httpClient: HttpClient) {}

  get<R>(
    route: string,
    opts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
    }
  ): Observable<R> {
    return this.httpClient.get<R>(route, opts);
  }

  post<P, R>(route: string, body: P): Observable<R> {
    return this.httpClient.post<R>(route, body);
  }

  put<P, R>(
    route: string,
    body: P,
    opts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
    }
  ): Observable<R> {
    return this.httpClient.put<R>(route, body, opts);
  }

  delete<R>(route: string): Observable<R> {
    return this.httpClient.delete<R>(route);
  }
}
